import moment from "moment";
import { Restaurant, RestaurantGQLPayload } from "../types";

export const getRestaurantPayload = (
  restaurant: Restaurant,
): RestaurantGQLPayload => {
  return {
    uuid: restaurant.uuid,
    name: restaurant.name,
    cuisine: restaurant.cuisine,
    website: restaurant.contact?.website,
    description: restaurant.details?.description,
    arrivalPolicy: restaurant.details?.arrivalPolicy,
    cancellationPolicy: restaurant.details?.cancellationPolicy,
    contactEmail: restaurant.contact?.email,
    contactName: restaurant.contact?.name,
    cityLocation: restaurant.city?.location,
    country: restaurant.city?.country,
    cityName: restaurant.city?.name,
    contactPhone: restaurant.contact?.phone,
    generalPolicy: restaurant.details?.generalPolicy,
    locationLatitude: Number(restaurant.location?.latitude ?? 0),
    locationLongitude: Number(restaurant.location?.longitude ?? 0),
    notes: restaurant.notes,
    seatCount: parseInt(String(restaurant.seatCount), 10),
    instagram: restaurant.contact?.instagram,
    addressLine1: restaurant.city?.addressLine1,
    addressLine2: restaurant.city?.addressLine2,
    zipCode: restaurant.city?.zipCode,
    listed: restaurant.listed,

    // Manager fields
    managerBirthDate: restaurant.manager?.birthdate
      ? moment(restaurant.manager?.birthdate).format("YYYY-MM-DD")
      : undefined,
    managerEmail: restaurant.manager?.email,
    managerPhone: restaurant.manager?.phone,
    managerFirstName: restaurant.manager?.firstName,
    managerLastName: restaurant.manager?.lastName,
  };
};
