import { DomainEnv, getEnv } from "../config";

export const getBackendUrl = () => {
  const subdomain = getEnv();
  if (subdomain === DomainEnv.prod) {
    return "https://api.accessmembers.com";
  }
  return `https://backend.${subdomain}.accessonboard.com`;
};

export const getAuthURL = () => {
  const subdomain = getEnv();
  if (subdomain === DomainEnv.prod) {
    return "https://identity.accessmembers.com";
  }

  return `https://keycloak.${subdomain}.accessonboard.com`;
};
