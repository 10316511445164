import { useMutation, useQuery } from "@apollo/client";
// import throttle from "lodash.throttle";
import moment from "moment";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Add, Check, Delete } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";

import { PricingRule, PricingRulePayload } from "@/app/types/PricingRule";
import { AppContext } from "../../context/AppContext";

import AccountHeader from "./AccountHeader";
import ConfirmModal from "../../components/common/ConfirmModal";
import { Severity, TableSizes, Weekdays } from "../../constants";
import {
  ADD_PRICING_RULE,
  GET_PRICING_RULE,
  REMOVE_PRICING_RULE,
  UPDATE_PRICING_RULE,
} from "../../graphql";
import { useParams } from "react-router-dom";
import { t } from "i18next";

interface PeriodItemChange {
  value: string | number | string[] | Date;
  tabIndex: number;
  tableSize: number;
  index: number;
  key: PricingRuleKey;
}

const CustomTab: any = styled((props) => <Tab {...props} />)(() => ({
  minWidth: 0,
}));

const CustomTabs: any = styled((props) => <Tabs {...props} />)(() => ({
  "& .MuiTabs-scroller": {
    overflowX: "scroll !important",
    marginRight: "80px",
  },
  minWidth: 0,
}));

const getTimeValue = (time: string) => {
  const [hour, minute] = time.split(":");
  return new Date().setHours(parseInt(hour), parseInt(minute));
};

function CustomTabPanel(props: {
  children: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface Period {
  id: string;
  period: moment.Moment;
  month: number;
  year: number;
  title: string;
}

type PricingRuleKey = keyof PricingRule;
const tableHeaders: { key: PricingRuleKey; title: string }[] = [
  {
    key: "dayOfWeek",
    title: "keyAllocation.dayOfWeek",
  },
  {
    key: "time",
    title: "keyAllocation.time",
  },
  {
    key: "tableTypes",
    title: "keyAllocation.tableTypes",
  },
  {
    key: "credit",
    title: "keyAllocation.keyValue",
  },
];

type KeyAllocationTable = {
  [period: string]: { [tableSize: string]: PricingRule[] };
};

function generatePeriodItems(periods: Period[]): KeyAllocationTable {
  const periodItems: KeyAllocationTable = {};
  const items: PricingRule[] = [];
  for (const period of periods) {
    periodItems[period.id] = {};
    for (const tableSize of Object.values(TableSizes)) {
      periodItems[period.id][tableSize] = items;
    }
  }

  return periodItems;
}

const weekDayOptions = Object.keys(Weekdays).map((i) => (
  <MenuItem value={(Weekdays as any)[i]} key={i}>
    {t(`weekdays.${i}`)}
  </MenuItem>
));

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function KeysAllocation() {
  const { t } = useTranslation();
  const { resyTableTypes, restaurant, setSnackBarMessageProps } =
    useContext(AppContext);

  // const [priceRuleChange, setPriceRuleChange] = useState<PricingRule | null>(
  //   null,
  // );
  const [isLoading, setIsLoading] = useState(false);
  const [addPriceRuleToRestaurant] = useMutation(ADD_PRICING_RULE);
  const [removePriceRule] = useMutation(REMOVE_PRICING_RULE);
  const [updatePriceRule] = useMutation(UPDATE_PRICING_RULE);
  const [tab, setTab] = useState(0);
  const { id: restaurantUuid } = useParams<{ id: string }>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const inputRef = useRef(null);
  // const throttled = useRef(
  //   throttle((rule: PricingRule | null) => handleUpdatePriceRule(rule), 4000),
  // );

  // useEffect(() => {
  //   throttled.current(priceRuleChange);
  // }, [priceRuleChange]);
  const {
    data: pricingRuleData,
    loading,
    error,
  } = useQuery<{
    priceRuleForRestaurant: PricingRule[];
  }>(GET_PRICING_RULE, {
    variables: {
      uuid: restaurantUuid,
    },
  });

  const [periods, setPeriods] = useState<Period[]>([]);
  const [periodItems, setPeriodItems] = useState<KeyAllocationTable>(
    generatePeriodItems(periods),
  );

  useEffect(() => {
    if (pricingRuleData?.priceRuleForRestaurant && !error && !loading) {
      const rules = pricingRuleData.priceRuleForRestaurant;
      const periodItems: KeyAllocationTable = {};
      const periodMap: { [key: string]: Period } = {};
      rules.forEach((rule) => {
        const periodId = `${rule.year}-${rule.month}`;
        if (!periodMap[periodId]) {
          const period = moment(`${rule.year}-${rule.month}`, "YYYY-MM");
          const title = period.format("MMM, YYYY");
          const id = `${rule.year}-${rule.month}`;
          periodMap[periodId] = {
            id,
            period,
            month: rule.month,
            year: rule.year,
            title,
          };
        }

        if (!periodItems[periodId]) {
          periodItems[periodId] = {};
        }

        if (!periodItems[periodId][rule.tableSize]) {
          periodItems[periodId][rule.tableSize] = [];
        }

        periodItems[periodId][rule.tableSize].push(rule);
      });

      const id = moment().format("YYYY-MM");
      if (Object.keys(periodMap).length === 0 || !periodMap[id]) {
        periodMap[id] = {
          id,
          period: moment(),
          month: moment().month() + 1,
          year: moment().year(),
          title: moment().format("MMM, YYYY"),
        };

        periodItems[id] = periodItems[id] || {};
        TableSizes.forEach((size) => {
          periodItems[id][size] = [];
        });
      }

      setPeriodItems(periodItems);
      setPeriods(
        Object.entries(periodMap)
          .map(([, value]) => value)
          .sort((a, b) => a.period.diff(b.period)),
      );
    }
  }, [pricingRuleData, error, loading]);

  const handleUpdatePriceRule = async (rule: PricingRule | null) => {
    if (!rule) {
      return;
    }

    const variables: PricingRulePayload = {
      uuid: rule.uuid,
      dayOfWeek: rule.dayOfWeek,
      time: rule.time,
      tableTypes: rule.tableTypes,
      credit: rule.credit,
      month: rule.month,
      year: rule.year,
      tableSize: rule.tableSize,
    };

    try {
      setIsLoading(true);
      const { errors } = await updatePriceRule({
        variables,
      });
      if (errors) {
        throw errors;
      }

      setSnackBarMessageProps?.({
        message: t("common.success"),
        severity: Severity.Success,
      });
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error"),
        severity: Severity.Error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePeriodItemChange = (periodItemProps: PeriodItemChange | null) => {
    if (!periodItemProps) {
      return;
    }

    const { key, value, index, tabIndex, tableSize } = periodItemProps;
    let newValue = value;
    switch (key) {
      case "uuid":
        return;
      case "credit":
        newValue = Number(value);
        break;
      case "time":
        newValue = moment(value).format("HH:mm:ss");
        break;
    }
    const { id: periodId } = periods[tabIndex];
    let rule: PricingRule | null = null;
    setPeriodItems((periodItems) => {
      return {
        ...periodItems,
        [periodId]: {
          ...periodItems[periodId],
          [tableSize]: periodItems[periodId][tableSize].map((item, idx) => {
            if (idx === index) {
              rule = {
                ...item,
                [key]: newValue,
              };

              return rule;
            }
            return item;
          }),
        },
      };
    });

    // Disable auto-save for now
    // setPriceRuleChange(rule);
  };

  function getRow({
    key,
    index,
    data,
    tabIndex,
    tableSize,
    resyTableTypes,
    isLoading,
  }: {
    key: PricingRuleKey;
    tabIndex: number;
    index: number;
    tableSize: number;
    data: PricingRule;
    resyTableTypes: string[];
    isLoading: boolean;
  }) {
    const periodId = periods[tabIndex].id;

    switch (key) {
      case "dayOfWeek":
        return (
          <FormControl fullWidth disabled={isLoading}>
            <Select
              ref={
                index + 1 === periodItems[periodId][tableSize]?.length
                  ? inputRef
                  : null
              }
              id={`${key}-${index}-input`}
              value={data.dayOfWeek}
              onChange={(e) =>
                handlePeriodItemChange({
                  key,
                  value: e.target.value,
                  index,
                  tabIndex,
                  tableSize,
                })
              }
            >
              {weekDayOptions}
            </Select>
          </FormControl>
        );
      case "time":
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimeField
              disabled={isLoading}
              onChange={(time) =>
                handlePeriodItemChange({
                  value: time?.toDate() || new Date(),
                  tabIndex,
                  tableSize,
                  index,
                  key,
                })
              }
              value={moment(getTimeValue(data.time))}
            />
          </LocalizationProvider>
        );
      case "tableTypes":
        return (
          <Autocomplete
            multiple
            disabled={isLoading}
            options={resyTableTypes}
            value={data.tableTypes}
            filterSelectedOptions
            onChange={(e, value) =>
              handlePeriodItemChange({
                value,
                tabIndex,
                tableSize,
                index,
                key,
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={isLoading}
                placeholder={t(`keyAllocation.tableTypesPlaceholder`)}
              />
            )}
          />
        );
      case "credit":
        return (
          <InputBase
            disabled={isLoading}
            id={`${key}-${index}-input`}
            margin="none"
            type="number"
            defaultValue={data.credit}
            sx={{ p: 0, m: 0 }}
            fullWidth
            placeholder={t(`keyAllocation.keyValuePlaceholder`)}
            onChange={(e) =>
              handlePeriodItemChange({
                value: e.target.value,
                tabIndex,
                tableSize,
                index,
                key,
              })
            }
          />
        );
      default:
        return <div></div>;
    }
  }

  async function handleRemovePeriodItem(
    tabIndex: number,
    tableSize: number,
    index: number,
  ) {
    const { id: periodId } = periods[tabIndex];
    try {
      setIsLoading(true);
      const { data, errors } = await removePriceRule({
        variables: {
          uuid: periodItems[periodId][tableSize][index].uuid,
        },
      });

      if (!data) {
        console.error(errors);
        throw new Error("Unable to remove price rule");
      }

      setPeriodItems((periodItems) => {
        return {
          ...periodItems,
          [periodId]: {
            ...periodItems[periodId],
            [tableSize]: periodItems[periodId][tableSize].filter(
              (_, idx) => idx !== index,
            ),
          },
        };
      });

      setSnackBarMessageProps?.({
        message: t("common.success"),
        severity: Severity.Success,
      });
    } catch {
      setSnackBarMessageProps?.({
        message: t("common.error"),
        severity: Severity.Error,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function createPricingRule(rule: PricingRule, periodId: string) {
    const variables: PricingRulePayload = {
      uuid: restaurant?.uuid,
      dayOfWeek: rule.dayOfWeek,
      time: rule.time,
      tableTypes: rule.tableTypes,
      credit: rule.credit,
      month: rule.month,
      year: rule.year,
      tableSize: rule.tableSize,
    };

    try {
      setIsLoading(true);
      const { data } = await addPriceRuleToRestaurant({
        variables,
      });

      const res: PricingRule = data.addPriceRuleToRestaurant;
      setPeriodItems((periodItems) => {
        return {
          ...periodItems,
          [periodId]: {
            ...periodItems[periodId],
            [rule.tableSize]: periodItems[periodId][rule.tableSize].map(
              (item) => {
                if (item.uuid === rule.uuid) {
                  return res;
                }
                return item;
              },
            ),
          },
        };
      });

      setSnackBarMessageProps?.({
        message: t("common.success"),
        severity: Severity.Success,
      });
    } catch (error) {
      console.error(error);
      setSnackBarMessageProps?.({
        message: t("common.error"),
        severity: Severity.Error,
      });

      setPeriodItems((periodItems) => {
        return {
          ...periodItems,
          [periodId]: {
            ...periodItems[periodId],
            [rule.tableSize]: periodItems[periodId][rule.tableSize].filter(
              (item) => item.uuid !== rule.uuid,
            ),
          },
        };
      });
    } finally {
      setIsLoading(false);
    }
  }

  function addPeriodItem(tabIndex: number, tableSize: number) {
    const { id: periodId, month, year } = periods[tabIndex];
    periodItems[periodId] = periodItems[periodId] ?? {};
    periodItems[periodId][tableSize] = periodItems[periodId][tableSize] ?? [];
    const dayOfWeek = Weekdays.Monday;
    const newPeriodItem: PricingRule = {
      uuid: `new-period-id-${new Date().getTime()}`,
      dayOfWeek,
      time: "12:00:00",
      tableTypes: [],
      credit: 10,
      month,
      year,
      tableSize: tableSize,
    };

    setPeriodItems((periodItems) => {
      return {
        ...periodItems,
        [periodId]: {
          ...periodItems[periodId],
          [tableSize]: [...periodItems[periodId][tableSize], newPeriodItem],
        },
      };
    });

    setTimeout(() => {
      (inputRef?.current as any)?.childNodes?.[0]?.focus?.();
    }, 250);
    return createPricingRule(newPeriodItem, periodId);
  }

  const handleChange = (event: SyntheticEvent, tab: number) => {
    setTab(tab);
  };

  const handleDeleteTab = (tab: number) => {
    setPeriods(periods.filter((_, index) => index !== tab));
    setTab(tab > 0 ? tab - 1 : 0);
    setIsDeleteModalOpen(false);
  };

  const appendPeriod = (e: SyntheticEvent) => {
    const period = periods[tab].period.add(1, "month");
    const periodPayload = {
      id: period.format("MM-YYYY"),
      period,
      month: period.month() + 1,
      year: period.year(),
      title: period.format("MMM, YYYY"),
    };
    setPeriods(
      [...periods, periodPayload].sort((a, b) => a.period.diff(b.period)),
    );
    setPeriodItems(generatePeriodItems([...periods, periodPayload]));
    handleChange(e, tab + 1);
  };

  if (!restaurant) {
    return null;
  }

  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        <Typography variant="h6">{t("keyAllocation.title")}</Typography>
        {periods.length > 0 && (
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            position="relative"
          >
            <CustomTabs
              value={tab}
              onChange={handleChange}
              visibleScrollbar
              aria-label={t("keyAllocation.title")}
              sx={{ overflowX: "scroll" }}
            >
              {periods?.map?.((period, index) => (
                <CustomTab
                  label={period.title}
                  key={index}
                  {...tabProps(index)}
                />
              ))}
            </CustomTabs>
            {/* <IconButton
              sx={{ position: "absolute", right: 0, top: 0 }}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <Delete />
            </IconButton> */}
            <IconButton
              sx={{ position: "absolute", right: 0, top: 0 }}
              onClick={appendPeriod}
            >
              <Add />
            </IconButton>
          </Box>
        )}
        {periods.length > 0 && (
          <CustomTabPanel value={tab} index={tab}>
            <TableContainer>
              <Table aria-label={periods[tab].title}>
                <TableHead>
                  <TableRow>
                    {tableHeaders?.map?.((header) => (
                      <TableCell key={header.key}>{t(header.title)}</TableCell>
                    ))}
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>

                {Object.values(TableSizes).map((tableSize) => (
                  <TableBody key={tableSize}>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography variant="h6">
                          {t(`keyAllocation.tableSizes-${tableSize}`)}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {periodItems[periods[tab].id][tableSize]?.map?.(
                      (row, index) => (
                        <TableRow key={index}>
                          {tableHeaders.map((header, periodIndex) => (
                            <TableCell
                              key={`${header.key}-${tableSize}-cell`}
                              scope="row"
                            >
                              {getRow({
                                key: header.key,
                                tableSize,
                                index,
                                tabIndex: tab,
                                data: row,
                                resyTableTypes,
                                isLoading,
                              })}
                            </TableCell>
                          ))}
                          <TableCell align="right">
                            <IconButton
                              disabled={isLoading}
                              onClick={() => handleUpdatePriceRule(row)}
                              aria-label={t("common.save")}
                            >
                              <Check />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleRemovePeriodItem(tab, tableSize, index)
                              }
                              aria-label={t("common.delete")}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ),
                    )}

                    <TableRow>
                      <TableCell colSpan={5}>
                        <Button
                          startIcon={<Add />}
                          onClick={() => addPeriodItem(tab, tableSize)}
                          type="button"
                          color="secondary"
                        >
                          {t("keyAllocation.addPeriodItem")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          </CustomTabPanel>
        )}
        {isDeleteModalOpen && (
          <ConfirmModal
            title={t("keyAllocation.delete")}
            inputIsOpen={isDeleteModalOpen}
            actionTitle={t("common.delete")}
            inputHandleClose={() => setIsDeleteModalOpen(false)}
            message={t("keyAllocation.deleteMessage")}
            handleConfirm={() => handleDeleteTab(tab)}
          />
        )}
      </Container>
    </div>
  );
}
