export enum UIMode {
  Light = "light",
  Dark = "dark",
}

export enum Severity {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
}

export enum LocalStorageKeys {
  Token = "arp-management-token",
  RefreshToken = "arp-management-refresh-token",
  UiMode = "arp-ui-mode",
}

export enum ReservationStatus {
  LISTED = "LISTED",
  AVAILABLE = "AVAILABLE",
  RESERVED = "RESERVED",
  CANCELED = "CANCELED",
  RELEASED = "RELEASED",
}

export enum Languages {
  EN = "en",
  DE = "de",
  ES = "es",
}

export enum Allergies {
  AllDairy = "allDairy",
  Eggs = "eggs",
  Fish = "fish",
  Milk = "milk",
  Peanuts = "peanuts",
  Sesame = "sesame",
  Shellfish = "shellfish",
  Soy = "soy",
  TreeNuts = "treeNuts",
  Wheat = "wheat",
}

export enum Restrictions {
  Diabetic = "diabetic",
  GlutenFree = "glutenFree",
  Halal = "halal",
  Kosher = "kosher",
  Paleo = "paleo",
  NoPork = "noPork",
  Pescatarian = "pescatarian",
  Vegan = "vegan",
  Vegetarian = "vegetarian",
}

export const KEYCLOAK_REALM = "ACCESS";
export const KEYCLOAK_CLIENT_ID = "ACCESS";

export const PRICE_RANGE_MAX = 5;

export const modalStyle = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "90%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  py: 2,
  px: 2,
};

export enum Currency {
  USD = "USD",
}

export enum CurrencySymbol {
  USD = "$",
}

export enum Cuisine {
  American = "American",
  Chinese = "Chinese",
  French = "French",
  Greek = "Greek",
  Indian = "Indian",
  Italian = "Italian",
  Japanese = "Japanese",
  Korean = "Korean",
  SmallPlates = "Small Plates",
  Sushi = "Sushi",
  Mexican = "Mexican",
  Seafood = "Seafood",
  Thai = "Thai",
  Vietnamese = "Vietnamese",
}

export enum MembershipPrices {
  premium = "$10.99",
  free = "$0.99",
  basic = "$7.99",
  platnum = "$22.99",
}

export enum Memberships {
  premium = "premium",
  free = "free",
  basic = "basic",
  platnum = "platnum",
}

export enum RestaurantPlatforms {
  Resy = "resy",
  OpenTable = "open-table",
}

export enum HistoryStatus {
  Cancelled = "cancelled",
  Completed = "completed",
  Upcoming = "upcoming",
}

export enum HistoryEvent {
  Reservation = "reservation",
  Credits = "credits",
}

export const TableSizes = [2, 4, 6];

export enum Weekdays {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export enum MemberStatus {
  Active = "active",
  Inactive = "inactive",
  Waitlist = "waitlist",
}

export enum CountryList {
  // AF = "AF",
  // AX = "AX",
  // AL = "AL",
  // DZ = "DZ",
  // AS = "AS",
  // AD = "AD",
  // AO = "AO",
  // AI = "AI",
  // AQ = "AQ",
  // AG = "AG",
  // AR = "AR",
  // AM = "AM",
  // AW = "AW",
  // AU = "AU",
  // AT = "AT",
  // AZ = "AZ",
  // BS = "BS",
  // BH = "BH",
  // BD = "BD",
  // BB = "BB",
  // BY = "BY",
  // BE = "BE",
  // BZ = "BZ",
  // BJ = "BJ",
  // BM = "BM",
  // BT = "BT",
  // BO = "BO",
  // BQ = "BQ",
  // BA = "BA",
  // BW = "BW",
  // BV = "BV",
  // BR = "BR",
  // IO = "IO",
  // BN = "BN",
  // BG = "BG",
  // BF = "BF",
  // BI = "BI",
  // KH = "KH",
  // CM = "CM",
  CA = "CA",
  // CV = "CV",
  // KY = "KY",
  // CF = "CF",
  // TD = "TD",
  // CL = "CL",
  // CN = "CN",
  // CX = "CX",
  // CC = "CC",
  // CO = "CO",
  // KM = "KM",
  // CG = "CG",
  // CD = "CD",
  // CK = "CK",
  // CR = "CR",
  // CI = "CI",
  // HR = "HR",
  // CU = "CU",
  // CW = "CW",
  // CY = "CY",
  // CZ = "CZ",
  // DK = "DK",
  // DJ = "DJ",
  // DM = "DM",
  // DO = "DO",
  // EC = "EC",
  // EG = "EG",
  // SV = "SV",
  // GQ = "GQ",
  // ER = "ER",
  // EE = "EE",
  // ET = "ET",
  // FK = "FK",
  // FO = "FO",
  // FJ = "FJ",
  // FI = "FI",
  // FR = "FR",
  // GF = "GF",
  // PF = "PF",
  // TF = "TF",
  // GA = "GA",
  // GM = "GM",
  // GE = "GE",
  // DE = "DE",
  // GH = "GH",
  // GI = "GI",
  // GR = "GR",
  // GL = "GL",
  // GD = "GD",
  // GP = "GP",
  // GU = "GU",
  // GT = "GT",
  // GG = "GG",
  // GN = "GN",
  // GW = "GW",
  // GY = "GY",
  // HT = "HT",
  // HM = "HM",
  // VA = "VA",
  // HN = "HN",
  // HK = "HK",
  // HU = "HU",
  // IS = "IS",
  // IN = "IN",
  // ID = "ID",
  // IR = "IR",
  // IQ = "IQ",
  // IE = "IE",
  // IM = "IM",
  // IL = "IL",
  // IT = "IT",
  // JM = "JM",
  // JP = "JP",
  // JE = "JE",
  // JO = "JO",
  // KZ = "KZ",
  // KE = "KE",
  // KI = "KI",
  // KP = "KP",
  // KR = "KR",
  // KW = "KW",
  // KG = "KG",
  // LA = "LA",
  // LV = "LV",
  // LB = "LB",
  // LS = "LS",
  // LR = "LR",
  // LY = "LY",
  // LI = "LI",
  // LT = "LT",
  // LU = "LU",
  // MO = "MO",
  // MK = "MK",
  // MG = "MG",
  // MW = "MW",
  // MY = "MY",
  // MV = "MV",
  // ML = "ML",
  // MT = "MT",
  // MH = "MH",
  // MQ = "MQ",
  // MR = "MR",
  // MU = "MU",
  // YT = "YT",
  // MX = "MX",
  // FM = "FM",
  // MD = "MD",
  // MC = "MC",
  // MN = "MN",
  // ME = "ME",
  // MS = "MS",
  // MA = "MA",
  // MZ = "MZ",
  // MM = "MM",
  // NA = "NA",
  // NR = "NR",
  // NP = "NP",
  // NL = "NL",
  // NC = "NC",
  // NZ = "NZ",
  // NI = "NI",
  // NE = "NE",
  // NG = "NG",
  // NU = "NU",
  // NF = "NF",
  // MP = "MP",
  // NO = "NO",
  // OM = "OM",
  // PK = "PK",
  // PW = "PW",
  // PS = "PS",
  // PA = "PA",
  // PG = "PG",
  // PY = "PY",
  // PE = "PE",
  // PH = "PH",
  // PN = "PN",
  // PL = "PL",
  // PT = "PT",
  // PR = "PR",
  // QA = "QA",
  // RE = "RE",
  // RO = "RO",
  // RU = "RU",
  // RW = "RW",
  // BL = "BL",
  // SH = "SH",
  // KN = "KN",
  // LC = "LC",
  // MF = "MF",
  // PM = "PM",
  // VC = "VC",
  // WS = "WS",
  // SM = "SM",
  // ST = "ST",
  // SA = "SA",
  // SN = "SN",
  // RS = "RS",
  // SC = "SC",
  // SL = "SL",
  // SG = "SG",
  // SX = "SX",
  // SK = "SK",
  // SI = "SI",
  // SB = "SB",
  // SO = "SO",
  // ZA = "ZA",
  // GS = "GS",
  // SS = "SS",
  // ES = "ES",
  // LK = "LK",
  // SD = "SD",
  // SR = "SR",
  // SJ = "SJ",
  // SZ = "SZ",
  // SE = "SE",
  // CH = "CH",
  // SY = "SY",
  // TW = "TW",
  // TJ = "TJ",
  // TZ = "TZ",
  // TH = "TH",
  // TL = "TL",
  // TG = "TG",
  // TK = "TK",
  // TO = "TO",
  // TT = "TT",
  // TN = "TN",
  // TR = "TR",
  // TM = "TM",
  // TC = "TC",
  // TV = "TV",
  // UG = "UG",
  // UA = "UA",
  // AE = "AE",
  // GB = "GB",
  US = "US",
  // UM = "UM",
  // UY = "UY",
  // UZ = "UZ",
  // VU = "VU",
  // VE = "VE",
  // VN = "VN",
  // VG = "VG",
  // VI = "VI",
  // WF = "WF",
  // EH = "EH",
  // YE = "YE",
  // ZM = "ZM",
  // ZW = "ZW",
}
