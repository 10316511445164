import { Dispatch, useEffect, useState } from "react";
import { SetStateAction } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  Button,
  Container,
  FormControl,
  Unstable_Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormEvent } from "react";

import { useTranslation } from "react-i18next";
import { Restaurant } from "@/app/types/Restaurant";

import { CountryList, Cuisine } from "../../constants";
import PageSpinner from "../../components/common/PageSpinner";
import moment from "moment";

export default function ReservationDetails({
  isOpen,
  setIsOpen,
  restaurant,
  submit,
  isLoading,
  setCachedRestaurant,
  requestErrors,
}: {
  isOpen: boolean;
  setCachedRestaurant: Dispatch<SetStateAction<Restaurant | null>>;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  restaurant: Restaurant;
  isLoading: boolean;
  requestErrors: { [key: string]: string | number };
  submit: (restaurant: Restaurant) => Promise<unknown>;
}) {
  const { t } = useTranslation();
  const [updatedRestaurant, setUpdatedRestaurant] =
    useState<Restaurant>(restaurant);
  const toggleDrawer = (newOpen: SetStateAction<boolean>) => () => {
    setIsOpen(newOpen);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const error = await submit(updatedRestaurant);
    if (!error) setIsOpen(false);
  };

  const setField = (
    field: string,
    value?: string | string[] | number | Date,
  ) => {
    const [parent, child] = field.split(".");
    let payload = { ...updatedRestaurant, [field]: value };
    if (child) {
      payload = {
        ...updatedRestaurant,
        [parent]: {
          ...(updatedRestaurant[parent as keyof Restaurant] as any),
          [child]: value,
        },
      };
    }

    setCachedRestaurant(payload);
    setUpdatedRestaurant(payload);
  };

  useEffect(() => {
    if (requestErrors) {
      Object.keys(requestErrors).forEach((key, i) => {
        if (i === 0) {
          document
            .getElementById(key)
            ?.scrollIntoView?.({ behavior: "smooth", block: "center" });
        }
      });
    }
  }, [requestErrors]);
  if (!updatedRestaurant) {
    return <PageSpinner />;
  }
  const width = window.innerWidth < 650 ? window.innerWidth - 20 : 650;
  const DrawerContent = (
    <Box sx={{ width }} role="presentation">
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={12} textAlign="right">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="h6">
          {t(restaurant.uuid ? "restaurants.update" : "restaurants.create")}
        </Typography>
        <Typography variant="body2" marginBottom={4}>
          {t(
            restaurant.uuid
              ? "restaurants.updateSubtitle"
              : "restaurants.createSubtitle",
          )}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box>
            <TextField
              id="name"
              value={updatedRestaurant.name}
              onChange={(e) => setField("name", e.target.value)}
              label={t("restaurants.name")}
              required
              variant="outlined"
              fullWidth
              placeholder="Don Angie"
              margin="normal"
            />
          </Box>

          <Box>
            <TextField
              id="description"
              value={updatedRestaurant.details?.description}
              onChange={(e) => setField("details.description", e.target.value)}
              label={t("restaurants.description")}
              required
              multiline
              minRows={2}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </Box>
          <Box>
            <TextField
              id="notes"
              value={updatedRestaurant.notes}
              multiline
              minRows={2}
              onChange={(e) => setField("notes", e.target.value)}
              label={t("restaurants.notes")}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </Box>
          <div className="flex flex-col md:flex-row gap-4">
            <Box flex="auto">
              <FormControl fullWidth required margin="normal">
                <InputLabel id="cuisine-label">
                  {t("restaurants.cuisine")}
                </InputLabel>
                <Select
                  labelId="cuisine-label"
                  id="cuisine"
                  multiple
                  label={t("restaurants.cuisine")}
                  value={updatedRestaurant.cuisine ?? []}
                  onChange={(e) => setField("cuisine", e.target.value)}
                >
                  {Object.values(Cuisine).map((cuisine) => (
                    <MenuItem value={cuisine} key={cuisine}>
                      {cuisine}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box flex="auto">
              <TextField
                id="seatCount"
                type="number"
                value={updatedRestaurant.seatCount}
                onChange={(e) => setField("seatCount", e.target.value)}
                label={t("profileEdit.seatCount")}
                variant="outlined"
                fullWidth
                placeholder="100"
                margin="normal"
              />
            </Box>
          </div>
          {!restaurant.uuid && (
            <Box>
              <Typography variant="h6" mt={4}>
                {t("profileEdit.managerInfo")}
              </Typography>
              <div className="flex flex-col md:flex-row gap-4">
                <Box flex="auto">
                  <TextField
                    id="managerFirstName"
                    type="text"
                    onChange={(e) =>
                      setField("manager.firstName", e.target.value)
                    }
                    label={t("profileEdit.managerFirstName")}
                    required
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box flex="auto">
                  <TextField
                    id="managerLastName"
                    type="text"
                    onChange={(e) =>
                      setField("manager.lastName", e.target.value)
                    }
                    label={t("profileEdit.managerLastName")}
                    required
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </div>
              <Box flex="auto">
                <TextField
                  error={!!requestErrors["managerEmail"]}
                  id="managerEmail"
                  type="email"
                  onChange={(e) => setField("manager.email", e.target.value)}
                  label={t("profileEdit.managerEmail")}
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="restaurant@example.com"
                  margin="normal"
                />
              </Box>
              <div className="flex flex-col md:flex-row gap-4">
                <Box flex="auto">
                  <TextField
                    id="managerPhone"
                    type="tel"
                    onChange={(e) => setField("manager.phone", e.target.value)}
                    label={t("profileEdit.managerPhone")}
                    required
                    variant="outlined"
                    fullWidth
                    placeholder="123-456-7890"
                    margin="normal"
                  />
                </Box>
                <Box mt={2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      defaultValue={moment(updatedRestaurant.manager.birthdate)}
                      onChange={(value) =>
                        setField("manager.birthdate", value?.toDate())
                      }
                      label={t("profileEdit.managerBirthdate")}
                    />
                  </LocalizationProvider>
                </Box>
              </div>
            </Box>
          )}
          <Box>
            <Typography variant="h6" mt={4}>
              {t("restaurants.contactInfo")}
            </Typography>
            <Box>
              <TextField
                id="contactName"
                type="text"
                value={updatedRestaurant.contact?.name}
                onChange={(e) => setField("contact.name", e.target.value)}
                label={t("profileEdit.contactName")}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Box>
            <div className="flex flex-col md:flex-row gap-4">
              <Box flex="auto">
                <TextField
                  id="phone"
                  type="tel"
                  value={updatedRestaurant.contact?.phone}
                  onChange={(e) => setField("contact.phone", e.target.value)}
                  label={t("restaurants.phone")}
                  variant="outlined"
                  fullWidth
                  placeholder="123-456-7890"
                  margin="normal"
                />
              </Box>

              <Box flex="auto">
                <TextField
                  id="email"
                  type="email"
                  value={updatedRestaurant.contact?.email}
                  onChange={(e) => setField("contact.email", e.target.value)}
                  label={t("restaurants.email")}
                  variant="outlined"
                  fullWidth
                  placeholder="restaurant@example.com"
                  margin="normal"
                />
              </Box>
            </div>
            <Box>
              <TextField
                id="website"
                type="url"
                value={updatedRestaurant.contact?.website}
                onChange={(e) => setField("contact.website", e.target.value)}
                label={t("restaurants.website")}
                variant="outlined"
                fullWidth
                placeholder="https://example.com"
                margin="normal"
              />
            </Box>

            <Box>
              <TextField
                id="instagram"
                type="text"
                value={updatedRestaurant.contact?.instagram}
                onChange={(e) => setField("contact.instagram", e.target.value)}
                label={t("profileEdit.instagram")}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="h6" mt={4}>
              {t("profileEdit.addressInfo")}
            </Typography>
            <Box>
              <TextField
                id="addressLine1"
                type="text"
                value={updatedRestaurant.city?.addressLine1}
                onChange={(e) => setField("city.addressLine1", e.target.value)}
                label={t("profileEdit.address")}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Box>
            <Box>
              <TextField
                id="addressLine2"
                type="text"
                multiline
                value={updatedRestaurant.city?.addressLine2}
                onChange={(e) => setField("city.addressLine2", e.target.value)}
                label={t("profileEdit.address2")}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Box>
            <div className="flex flex-col md:flex-row gap-4">
              <Box flex="auto">
                <TextField
                  id="cityName"
                  type="text"
                  value={updatedRestaurant.city?.name}
                  onChange={(e) => setField("city.name", e.target.value)}
                  label={t("profileEdit.cityName")}
                  variant="outlined"
                  fullWidth
                  placeholder="New York"
                  margin="normal"
                />
              </Box>
              <Box flex="auto">
                <TextField
                  id="location"
                  type="text"
                  value={updatedRestaurant.city?.location}
                  onChange={(e) => setField("city.location", e.target.value)}
                  label={t("profileEdit.locationName")}
                  variant="outlined"
                  fullWidth
                  placeholder=""
                  margin="normal"
                />
              </Box>
            </div>
            <div className="flex flex-col md:flex-row gap-4">
              <Box flex="1">
                <TextField
                  id="zipCode"
                  type="number"
                  value={updatedRestaurant.city?.zipCode}
                  onChange={(e) => setField("city.zipCode", e.target.value)}
                  label={t("profileEdit.zipCode")}
                  variant="outlined"
                  fullWidth
                  placeholder="11101"
                  margin="normal"
                />
              </Box>
              <Box flex="auto">
                <FormControl fullWidth margin="normal">
                  <InputLabel id="country-label">
                    {t("profileEdit.country")}
                  </InputLabel>
                  <Select
                    labelId="country-label"
                    name="country"
                    id="country"
                    label={t("profileEdit.country")}
                    value={updatedRestaurant.city?.country}
                    onChange={(e) => setField("city.country", e.target.value)}
                  >
                    {Object.values(CountryList).map((country) => (
                      <MenuItem value={country} key={country}>
                        {t(`countries.${country}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <Box>
              <Typography variant="h6" mt={4}>
                {t("profileEdit.cordinatesInfo")}
              </Typography>
              <div className="flex flex-col md:flex-row gap-4">
                <Box flex="auto">
                  <TextField
                    id="longitude"
                    type="number"
                    value={updatedRestaurant.location?.longitude}
                    onChange={(e) =>
                      setField("location.longitude", e.target.value)
                    }
                    label={t("profileEdit.longitude")}
                    variant="outlined"
                    fullWidth
                    placeholder="40.7128"
                    margin="normal"
                  />
                </Box>
                <Box flex="auto">
                  <TextField
                    id="latitude"
                    type="number"
                    value={updatedRestaurant.location?.latitude}
                    onChange={(e) =>
                      setField("location.latitude", e.target.value)
                    }
                    label={t("profileEdit.latitude")}
                    variant="outlined"
                    fullWidth
                    placeholder="40.7128"
                    margin="normal"
                  />
                </Box>
              </div>
            </Box>
            <Box>
              <Typography variant="h6" mt={4}>
                {t("profileEdit.detailsInfo")}
              </Typography>
              <Box>
                <TextField
                  id="arrivalPolicy"
                  type="text"
                  multiline
                  minRows={2}
                  value={updatedRestaurant.details?.arrivalPolicy}
                  onChange={(e) =>
                    setField("details.arrivalPolicy", e.target.value)
                  }
                  label={t("profileEdit.arrivalPolicy")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Box>
              <Box>
                <TextField
                  id="cancellationPolicy"
                  type="text"
                  multiline
                  minRows={2}
                  value={updatedRestaurant.details?.cancellationPolicy}
                  onChange={(e) =>
                    setField("details.cancellationPolicy", e.target.value)
                  }
                  label={t("profileEdit.cancellationPolicy")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Box>
              <Box>
                <TextField
                  id="generalPolicy"
                  type="text"
                  multiline
                  minRows={2}
                  value={updatedRestaurant.details?.generalPolicy}
                  onChange={(e) =>
                    setField("details.generalPolicy", e.target.value)
                  }
                  label={t("profileEdit.generalPolicy")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Box>
            </Box>
          </Box>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ my: 2 }}
            disabled={isLoading}
          >
            {t(restaurant.uuid ? "restaurants.update" : "restaurants.create")}
          </Button>
        </form>
      </Container>
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        {DrawerContent}
      </Drawer>
    </div>
  );
}
