import { SetStateAction, useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Reservation } from "../../types/Reservation";
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslation } from "react-i18next";
import moment from "moment";
import MemberProfile from "../../components/common/MemberProfile";
import { getDateValue } from "../../utils/getDateValue.util";
import { AppContext } from "../../context/AppContext";

export default function ReservationDetails({
  isOpen,
  setIsOpen,
  reservation,
}: {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  reservation: Reservation;
}) {
  const { t } = useTranslation();
  const { restaurant } = useContext(AppContext);
  const toggleDrawer = (newOpen: SetStateAction<boolean>) => () => {
    setIsOpen(newOpen);
  };

  const mailtoSubject = encodeURIComponent(
    t("reservations.mailtoSubject", {
      location: restaurant?.name,
    }),
  );

  const mailtoBody = encodeURIComponent(
    t("reservations.mailtoBody", {
      table: reservation.table?.location + " → " + reservation.table?.label,
    }),
  );

  const width = window.innerWidth < 550 ? window.innerWidth - 20 : 550;
  const DrawerContent = (
    <Box sx={{ width }} role="presentation">
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="right">
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="h6">{t("reservations.titleDetail")}</Typography>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t("reservations.date")}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Tooltip
                title={moment(getDateValue(reservation.date.start)).fromNow()}
                placement="top-end"
              >
                <Typography variant="body2">
                  {moment(getDateValue(reservation.date.start)).format(
                    "MMM DD, YYYY",
                  )}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t("reservations.time")}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {moment(getDateValue(reservation.date.start)).format("h:mm A")}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.guests")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation.table.maxPartySize}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">{t("reservations.table")}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation.table.location} &rarr; {reservation.table.label}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.memberRestrictions")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation?.member?.restrictions?.join(", ") || "—"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.memberNote")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation.member?.note ?? "—"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ padding: 2, marginTop: 4 }} elevation={1}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("reservations.allergies")}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                {reservation.member?.allergies?.join(", ") || "—"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      {reservation?.member && <Divider sx={{ marginTop: 4 }} />}
      {reservation.member && (
        <Container sx={{ padding: 2 }}>
          <Typography variant="body1" paddingY={2}>
            {t("reservations.member")}
          </Typography>
          <MemberProfile member={reservation.member} />
        </Container>
      )}
      {reservation?.member && <Divider sx={{ marginTop: 2 }} />}
      {reservation.member && (
        <Container sx={{ padding: 2 }}>
          <a
            href={`mailto:${reservation.member?.email}?subject=${mailtoSubject}&body=${mailtoBody}`}
          >
            <Button color="primary">{t("reservations.contactMember")}</Button>
          </a>
        </Container>
      )}
    </Box>
  );

  return (
    <div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        {DrawerContent}
      </Drawer>
    </div>
  );
}
